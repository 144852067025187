<div class="linkedin-feed">
    <!-- Indicateur de chargement -->
    <div *ngIf="isLoading" class="loading-spinner">
        <p>Chargement des posts LinkedIn...</p>
    </div>

    <!-- Gestion des erreurs -->
    <div *ngIf="error" class="error-message">
        <p>{{ error }}</p>
    </div>

    <!-- Liste des posts -->
    <div *ngIf="!isLoading && !error && linkedInPosts.length > 0">
        <div class="carousel">
            <div class="carousel-slide" *ngFor="let post of linkedInPosts">
                <iframe [src]="generateEmbedLink(post.postId)" width="100%" height="500px" frameborder="0" allowfullscreen
                    title="LinkedIn post"></iframe>
            </div>
        </div>
    </div>

    <!-- Message si aucun post -->
    <div *ngIf="!isLoading && linkedInPosts.length === 0">
        <p>Aucun post LinkedIn disponible.</p>
    </div>
</div>