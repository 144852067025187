<section class="hero__section">
  <div class="container-fluid">
    <div class="row">
      <app-donation-onglet></app-donation-onglet>
      <div class="col-xxl-5 col-lg-6 col-md-12 d-flex justify-content-center d-lg-block d-none">
        <img src="./../../assets/image-home-patis-fraux.png" class="img-fluid hero__img">
      </div>
      <div class="col-xxl-5 col-lg-6 col-md-12 mt-lg-0 mt-4">
        <span class="pole-sanitaire__span">Des activités sanitaires et médico-sociales</span>
        <h1 class="bienvenue__h2">Bienvenue à l’Association Le Patis Fraux</h1>
        <p class="hero-text__p">
          Acteur du privé solidaire, l’Association Le Patis Fraux, gère des établissements et services
          sanitaires et médico-sociaux sur le champ du handicap et des personnes âgées.<br/><br/>

          Par l’engagement de ses équipes, l’établissement assure une prise en charge et un
          accompagnement en adéquation avec les besoins et les attentes des personnes en situation
          de fragilité liés à leur handicap et/ou leur âge.<br/><br/>

          L’Association est présente sur deux sites situés à Vern-sur-Seiche et gère :<br>
        </p>
        <ul class="hero-text__p">
          <li>
            1 établissement de santé privé d'intérêt collectif (ESPIC) de soins médicaux et de réadaptation
          </li>
          <li>
            6 établissements et services médico-sociaux : Accueil Temporaire (Foyer d’Accueil
            Médicalisé et Foyer de Vie), Service de Réadaptation Professionnelle (SRP), EHPAD,
            Foyer d’Hébergement pour Adultes Handicapés (FHAH), Service d’Accompagnement à
            la Vie Sociale (SAVS) et Service d’Accompagnement Médico-Social pour Adultes
            Handicapés (SAMSAH).
          </li>
        </ul>
        <p class="hero-text__p">
          L’Association Le Patis Fraux porte également une Maison Sport Santé (Patis F’orm), un
          organisme de formation (Patis Learn) ainsi qu’un Habitat Inclusif (Patis Home).<br/><br/>

          L’Association est adhérente à la Fédération des Etablissements Hospitaliers et d’Aide à la
          Personne à but non lucratif (FEHAP), au Réseau GRAAL 35, à l’Union Régionale Interfédérale
          des Organismes Privés Sanitaires et Sociaux (URIOPSS Bretagne) et à la FAGERH (fédère les
          établissements de réadaptation professionnelle).<br/><br/>
        </p>
        <a [routerLink]="['/contactez-nous']"  class="btn btn-contact shadow-none">CONTACTEZ-NOUS</a>
      </div>
      <div class="col-xxl-5 col-lg-6 col-md-12 text-center d-lg-none d-md-block">
        <img src="./../../assets/image-home-patis-fraux.png" class="img-fluid hero__img">
      </div>
    </div>
  </div>
</section>
<section class="actu__section">
  <div class="container-fluid g-0">
    <div class="row">
      <h2>Actualités</h2>
      <div class="col-lg-4 col-md-12" *ngFor="let a of actu">
        <div class="card">
          <a href="#" [routerLink]="['/actualites', a.slug]" >
            <div style="background-image: url({{a.urlImage}});" class="card-img-top"></div>
            <img class="puce" src="../../assets/puce-texte.png">
            <div class="card-body">
              <h5 class="card-title"><span>—</span> {{ a.titre }}</h5>
              <p class="card-text">
                {{ a.texte_vignette }}
              </p>
              <p class="text-muted">{{ a.dateCreation.date | date : 'd LLLL, y' : '' : 'fr' }}</p>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <app-linkedin> </app-linkedin>
      </div>
    </div>
    
    
    <!-- <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" style="overflow:hidden">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" *ngFor="let slide of slider; let index = index; let isFirst = first" [ngClass]="{'active': isFirst }" [attr.data-bs-slide-to]="index" aria-current="isFirst"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item" [ngClass]="{'active': isFirst }" *ngFor="let slide of slider; first as isFirst">
          <div class="items-container">
            <div class="carousel-title">
              <h3 class="readapation__h3">{{slide.titre}}</h3>
              <p class="readapation__sousTitre">{{slide.sousTitre}}</p>
              <a *ngIf="slide.lien" href="{{slide.lien}}" class="btn btn-contact shadow-none">{{slide.titre_button}}</a>
            </div>
            <img src="{{slide.urlImage}}" class="d-block h-100">
          </div>
        </div>

      </div>
       <button *ngIf="slider.length > 1" class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button *ngIf="slider.length > 1" class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div> -->
  </div>
  <!-- <div class="blank_separation"></div> -->
  <div class="container-fluid g-0 quelque-chiffres_container">
    <div class="chiffres-text">
      <h3>Association Le Patis Fraux <br/><span>en quelques chiffres</span></h3>
      <div class="chiffres">
        <h4 *ngFor="let chiffre of chiffres">
          {{ chiffre.nombre }} <span>{{ chiffre.texte }}</span>
        </h4>
      </div>
    </div>
    <div class="qq-chiffres-img">
      <img src="./../../assets/photo-quelques-chiffres.png" alt="photo-qualité">
      <!-- <a class="play-img" href="#">
        <img src="./../../assets/play.svg">
      </a> -->
    </div>
  </div>
</section>

<section class="recrutement__section">
  <div class="container-fluid g-0">
    <span>Recrutement</span>
    <h2>Toutes nos offres</h2>
    <a [routerLink]="['/nous-rejoindre']" class="btn btn-recrutement shadow-none">Voir toutes nos offres</a>
    <div class="row justify-content-xxl-start justify-content-center">
      <div class="col-xxl-7 col-xl-10">
        <div class="offres-container d-flex justify-content-xxl-start justify-content-center">
          <div class="card-container" *ngIf="nbOffre >= 1; else elseBlock">
            <a class="link-offre" [routerLink]="['/nous-rejoindre', offre.slug]" *ngFor="let offre of offres">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{ offre.titre }}</h5>
                  <p class="card-text">{{ offre.texteVignette }}</p>
                  <span class="card-date">{{ offre.date.date | date : 'd LLLL, y' : '' : 'fr' }}</span>
                </div>
                <a class="btn">
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>
            </a>
          </div>
          <ng-template #elseBlock>
            <div class="card-container text-center d-flex justify-content-center align-items-center">
              <p class="card-title">Aucune offre disponible</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>
