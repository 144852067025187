import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { LinkedInService } from "src/app/_services/linkedin.service";

@Component({
	selector: "app-linkedin",
	templateUrl: "./linkedin.component.html",
	styleUrls: ["./linkedin.component.scss"],
})
export class LinkedInComponent implements OnInit {
	linkedInPosts: any[] = []; // Liste des posts
	isLoading = true; // Indicateur de chargement
	error: string | null = null; // Gestion des erreurs

	constructor(
		private linkedInService: LinkedInService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit(): void {
		this.loadLinkedInPosts();
	}

	// Charger les posts LinkedIn
	loadLinkedInPosts(): void {
		this.linkedInService.getPosts().subscribe({
			next: (response) => {
				this.linkedInPosts = response || [];
				this.isLoading = false;
			},
			error: (err) => {
				console.error(
					"Erreur lors du chargement des posts LinkedIn :",
					err
				);
				this.error = "Impossible de charger les posts LinkedIn.";
				this.isLoading = false;
			},
		});
	}

	// Générer un lien sûr pour l'embed
	generateEmbedLink(urn: string): SafeResourceUrl {
		const url = `https://www.linkedin.com/embed/feed/update/${encodeURIComponent(
			urn
		)}`;
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
