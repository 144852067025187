import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class LinkedInService {
	private api = environment.api;

	constructor(private http: HttpClient) {}

	getPosts(): Observable<any> {
		return this.http.get(`${this.api}/api/linkedin/posts`);
	}
}
